<template>
  <entity-form
    :id="user ? user.id : null"
    module-name="usersList"
    :model="form"
    :before-submit="beforeSubmit"
    :automatic-reload="false"
    @submit="onSubmit"
  >
    <b-form-group
      label="First Name"
    >
      <b-form-input
        v-model="form.firstName"
        placeholder="First Name"
      />
    </b-form-group>

    <b-form-group
      label="Last Name"
    >
      <b-form-input
        v-model="form.lastName"
        placeholder="Last Name"
      />
    </b-form-group>

    <b-form-group
      v-if="!user"
      label="Password"
    >
      <b-form-input
        v-model="form.password"
        placeholder="Password"
      />
    </b-form-group>

    <b-form-group
      label="Phone"
    >
      <b-form-input
        v-model="form.phone"
        placeholder="Phone"
      />
    </b-form-group>

    <b-form-group
      label="Email"
    >
      <b-form-input
        v-model="form.email"
        placeholder="Email"
      />
    </b-form-group>

    <b-form-group
      label="Language"
    >
      <language-select v-model="form.language" />
    </b-form-group>

    <b-form-group
      label="Country"
    >
      <country-select v-model="form.country" />
    </b-form-group>

    <b-form-group
      label="Tags"
    >
      <b-badge
        v-for="(item, index) in form.tags"
        :key="index"
        class="m-50"
        :variant="item.tag.color"
      >
        {{ item.tag.name }}
        <b-button
          class="btn-icon rounded-circle"
          size="sm"
          :variant="item.tag.color"
          @click="removeTag(item.tag.id)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-badge>
      <custom-select
        module-name="tagsList"
        label="name"
        not-reduce-by-id
        @input="addTag"
      />
    </b-form-group>

    <b-form-group
      label="Disable user form purchasing new accounts"
    >
      <b-form-checkbox
        v-model="form.purchase_disabled"
        class="mt-1"
      >
        Purchase disabled
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Allow user to login with VPN"
    >
      <b-form-checkbox
        v-model="form.allowedVpn"
        class="mt-1"
      >
        Allow VPN
      </b-form-checkbox>
    </b-form-group>

    <multi-entity-select
      v-model="form.system_rules"
      class="mt-1"
      :filter="ruleFilter"
    />

    <app-collapse>
      <app-collapse-item
        title="Allowed products"
      >
        <product-tree-select
          :products-list="form.allowed_products"
          @selected="value => productIds = value"
        />
      </app-collapse-item>
    </app-collapse>

    <b-form-group class="mt-1">
      <label>Comment</label>
      <b-form-textarea
        v-model="form.comment"
        placeholder="Comment"
        rows="8"
      />
    </b-form-group>
  </entity-form>
</template>

<script>
import {
  BFormGroup, BFormInput, BBadge, BButton, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import { dispatch, get } from 'vuex-pathify'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import LanguageSelect from '@/components/ui/forms/LanguageSelect.vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import { formatCurrency } from '@/plugins/formaters'
import ProductTreeSelect from '@/components/ui/forms/ProductTreeSelect.vue'
import MultiEntitySelect from '@/components/ui/gqlforms/MultiEntitySelect.vue'

export default {
  components: {
    MultiEntitySelect,
    ProductTreeSelect,
    AppCollapse,
    AppCollapseItem,
    EntityForm,
    CountrySelect,
    LanguageSelect,
    CustomSelect,
    BFormInput,
    BFormGroup,
    BBadge,
    BButton,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    user: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        firstName: this.user ? this.user.firstName : null,
        lastName: this.user ? this.user.lastName : null,
        password: this.user ? this.user.password : null,
        phone: this.user ? this.user.phone : null,
        email: this.user ? this.user.email : null,
        language: this.user ? this.user.language.id : null,
        country: this.user ? this.user.country.id : null,
        tags: this.user ? this.user.tags : [],
        allowed_products: this.user ? this.user.allowed_products : [],
        purchase_disabled: this.user ? this.user.purchase_disabled : false,
        allowedVpn: this.user ? this.user.allowedVpn : false,
        comment: this.user ? this.user.comment : null,
        system_rules: this.user ? this.user.system_rules : [],
      },
      productIds: [],
      ruleFilter: {
        name: 'rules',
        label: 'System rules',
        filterable: {
          type: 'entity', queryType: 'rules', label: 'name', search: 'name', value: 'id',
        },
      },
    }
  },
  computed: {
    ...get('auth', ['me']),
  },
  mounted() {
    dispatch('auth/getMe')
  },
  methods: {
    formatCurrency,
    beforeSubmit() {
      this.form.tags = this.form.tags.map(item => ({ ...item, assigner: typeof item.assigner === 'string' || item.assigner === null ? item.assigner : item.assigner.id }))
      this.form.allowed_products = []
      this.productIds.forEach(id => {
        this.form.allowed_products.push({ id })
      })

      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
    addTag(item) {
      if (!this.form.tags.find(tagToUser => tagToUser.tag.id === item.id)) this.form.tags.push({ tag: item, user: this.user.id, assigner: this.me.id })
    },
    removeTag(id) {
      this.form.tags = this.form.tags.filter(item => item.tag.id !== id)
    },
  },
}
</script>

<style scoped>

</style>
